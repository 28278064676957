<template>
  <b-row align-h="center">
    <b-col cols="8">
      <b-row>
        <b-col md="4">
          <b-form-group
            label="使用者帳號"
            label-for="username"
          >
            <b-form-input
              id="username"
              placeholder="使用者帳號"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="使用者名稱"
            label-for="disName"
          >
            <b-form-input
              id="disName"
              placeholder="使用者名稱"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="First Name"
            label-for="mc-first-name"
          >
            <b-form-input
              id="mc-first-name"
              placeholder="First Name"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        align-h="center"
        class="mt-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="show()"
        >
          確定
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          class="mr-1"
        >
          取消
        </b-button>
      </b-row>
    </b-col>
    <b-col cols="6">
      <b-card
        title="Create Awesome 🙌"
        @click="show()"
      >
        <b-card-text>This is your second page.</b-card-text>
        <b-card-text>Chocolate sesame snaps pie carrot cake pastry pie lollipop muffin. Carrot cake dragée chupa chups jujubes. Macaroon liquorice cookie wafer tart marzipan bonbon. Gingerbread jelly-o dragée chocolate.</b-card-text>
      </b-card>
    </b-col>
    <!-- modal login-->
    <b-modal
      id="modal-login"
      ok-title="確定"
      cancel-title="取消"
      centered
      title="編輯室窗"
      :no-close-on-backdrop="true"
      @ok="handleOk"
    >
      <!-- size="xl" -->
      確定要編輯嗎?
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BCol, BRow, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tableBasic: [],
      counter: 0,
    }
  },
  created() {
    // const r = sessionStorage.getItem('token')
    // const headers = {
    //   Authorization: r,
    // }
    // const params = {
    //   cusNo: '83267911',
    //   orgId: '1',
    // }
    // this.$http.get('../api/bill/getBills', { params, headers }).then(res => {
    //   console.log(res.data.data)
    //   res.data.data.forEach(e => {
    //     console.log(e)
    //     this.tableBasic.push(e)
    //   })
    // })
    // this.tableBasic.push(
    //   {
    //     id: 1,
    //     fullName: 'timfna',
    //     email: 'kocrevy0@thetimes.co.uk',
    //     startDate: '09/23/2016',
    //     salary: '$23896.35',
    //     status: 'Professional',
    //   },
    // )
  },
  methods: {
    show() {
      this.$bvModal.show('modal-login')
    },
    toast(toaster, append = false) {
      this.counter += 1
      this.$bvToast.toast('更新成功', {
        title: `Toaster ${toaster}`,
        toaster,
        variant: 'success',
        solid: false,
        appendToast: append,
      })
    },
    handleOk(p) {
      this.toast('b-toaster-top-center', false)
      // this is for submit
      p.preventDefault()
      const rs = true
      if (rs) {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-login')
        })
      }
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
